<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="Add Additional Payment"
      slot="title"
      link="/helpContent/additional"
    />
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-2">
          <FormSelect
            label="Select Staff"
            :items="staffList"
            item-name="Name"
            item-value="id"
            v-model="form.staffId"
            rules="required"
          />
        </div>
        <div class="col-sm-2">
          <Year label="Year" v-model="form.year" rules="required" />
        </div>
        <div class="col-sm-2">
          <InputMonth
            label="Month"
            :items="monthsList"
            item-name="NAME"
            item-value="id"
            v-model="form.month"
          />
        </div>
    
        
      </FormRow>
      <FormRow>
        <div class="col-sm-2">
          <FormSelect
            label="Select Payment"
            :items="paymentList"
            item-name="Name"
            item-value="id"
            v-model="form.allowanceId"
            :onChange="modeSet"
            rules="required"
          />
        </div>
        <div class="col-sm-2">
          <InputAmount label="Amount" v-model="form.amount" rules="required" />
        </div>
        <div class="col-sm-4">
          <InputTextArea label="Note" v-model="form.note" rules="required" />
        </div>
      </FormRow>
      <FormRow v-if="container">
        <div class="col-sm-2">
          <InputIntegerNumber label="No. of days" v-model="form.daystoPaid" rules="required" />
        </div>
        <div class="col-sm-2">
          <Year label="Year" v-model="form.yeartopayfor" rules="required" />
        </div>
        <div class="col-sm-2">
          <InputMonth
            label="Month"
            :items="monthsList"
            item-name="NAME"
            item-value="id"
            v-model="form.monthtopayfor"
          />
        </div>
      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>

    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { getAllStaff,getAdditionalIncentiveList,addStaffAdditionalPayment,updateStaffAdditionalpayment,getAdditionalPaymentById } from "../api";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
import Year from "../../../components/Year";
export default {
  name: "add",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    Year,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      form: {
        staffId: null,
        month: null,
        year: null,
        note: "",
        allowanceId: null,
        amount: null,
        yeartopayfor:null,
        monthtopayfor:null,
        daystoPaid:null,
        id:0,
      },
      staffList: [],
      monthsList: [],
      paymentList: [],
      container:false,
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},

  created() {
    this.getDropDowns();
  },
  methods: {
    async getDropDowns() {
      await getAllStaff().then((res) => {
        this.staffList = res.data;
        this.form.staffId = this.userData.user_id;
      });
      await getAdditionalIncentiveList().then((res) => {
        this.paymentList = res.data;
      });
      if (this.$route.params.id) {
        this.form.id=atob(this.$route.params.id);
        this.getData();
      }
    },
    modeSet(id){
// days paid debit
if(id==25){
this.container=true;
}
else{
  this.container=false;
      this.form.monthtopayfor=null;
            this.form.yeartopayfor=null;
           this.form.daystoPaid=null;
}
    },
    sendData() {
      this.showMessage.isVisible = false;
      this.infoMessage.isVisible = false;
      this.$refs.validator.validate().then((result) => {
        if (result) {
          const data = {
            staffId: this.form.staffId,
            month: this.form.month,
            year: this.form.year,
            note: this.form.note,
            allowanceId: this.form.allowanceId,
            amount: this.form.amount,
            id:this.form.id,
            monthtopayfor: this.form.monthtopayfor,
            yeartopayfor: this.form.yeartopayfor,
            daystoPaid: this.form.daystoPaid,
          };
          if (this.form.id===0) {
            addStaffAdditionalPayment(data).then((res) => {
            if (res.data[0].status > 1) {
              this.infoMessage.isVisible = true;
              this.infoMessage.message = res.data[0].message;
            } else {
              this.form = {};
              this.showMessage.isVisible = true;
              this.showMessage.message = res.data[0].message;
            }
          });
          } else {
            updateStaffAdditionalpayment(data).then((res) => {
            if (res.data[0].status > 1) {
              this.infoMessage.isVisible = true;
              this.infoMessage.message = res.data[0].message;
            } else {
              this.form = {};
              this.showMessage.isVisible = true;
              this.showMessage.message = res.data[0].message;
            }
          });
          }
     
        }
      });
    },
    getData(){
    const data={
      id:this.form.id
    }
    getAdditionalPaymentById(data).then(res=>{
     this.form.staffId=res.data[0].StaffId;
           this.form.month=res.data[0].Month;
            this.form.year=res.data[0].Year;
           this.form.note=res.data[0].Notes;
            this.form.allowanceId=res.data[0].AllowanceId;
            this.form.amount=res.data[0].Amount;
            if(res.data[0].AllowanceId===25){
              this.container=true;
              this.form.monthtopayfor=res.data[0].monthtopayfor;
            this.form.yeartopayfor=res.data[0].Yeartopayfor;
           this.form.daystoPaid=res.data[0].DaysToPay;
            }
      })
    }
  },
};
</script>
